// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._c-0 {\n  display: flex;\n}\n\n._c-1 {\n  flex-direction: row;\n}\n\n._c-1._c-2 {\n    flex-direction: row-reverse;\n  }\n._c-3 {\n  flex-direction: column;\n}\n._c-3._c-2 {\n    flex-direction: column-reverse;\n  }\n\n._c-4 {\n  align-items: center;\n}\n._c-5 {\n  align-items: flex-start;\n}\n._c-6 {\n  align-items: flex-end;\n}\n._c-7 {\n  align-items: stretch;\n}\n._c-8 {\n  align-items: baseline;\n}\n\n._c-9 {\n  align-self: center;\n}\n._c-a {\n  align-self: flex-start;\n}\n._c-b {\n  align-self: flex-end;\n}\n._c-c {\n  align-self: stretch;\n}\n._c-d {\n  align-self: baseline;\n}\n\n._c-e {\n  justify-content: center;\n}\n._c-f {\n  justify-content: flex-start;\n}\n._c-g {\n  justify-content: flex-end;\n}\n._c-h {\n  justify-content: space-between;\n}\n._c-i {\n  justify-content: space-around;\n}\n._c-j {\n  justify-content: space-evenly;\n}\n\n._c-k {\n  flex-wrap: wrap;\n}\n\n._c-l {\n  flex-wrap: nowrap;\n}\n\n._c-m {\n  flex-wrap: wrap-reverse;\n}\n", ""]);
// Exports
export var flex = "_c-0";
export var row = "_c-1";
export var reverse = "_c-2";
export var column = "_c-3";
export var alignCenter = "_c-4";
export var alignFlexStart = "_c-5";
export var alignFlexEnd = "_c-6";
export var alignStretch = "_c-7";
export var alignBaseline = "_c-8";
export var alignSelfCenter = "_c-9";
export var alignSelfFlexStart = "_c-a";
export var alignSelfFlexEnd = "_c-b";
export var alignSelfStretch = "_c-c";
export var alignSelfBaseline = "_c-d";
export var justifyCenter = "_c-e";
export var justifyFlexStart = "_c-f";
export var justifyFlexEnd = "_c-g";
export var justifySpaceBetween = "_c-h";
export var justifySpaceAround = "_c-i";
export var justifySpaceEvenly = "_c-j";
export var wrapWrap = "_c-k";
export var wrapNowrap = "_c-l";
export var wrapWrapReverse = "_c-m";
export default ___CSS_LOADER_EXPORT___;
