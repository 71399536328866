// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._j-0 {\n  display: flex;\n  flex-direction: column;\n}\n\n._j-1 {\n  font-weight: bold;\n  font-size: var(--redo-body-small-text-size);\n}\n\n.MuiPopper-root {\n  z-index: 1500;\n}\n\n._j-2 {\n  color: var(--redo-colors-text-text-primary-900);\n  background-color: var(--redo-colors-background-bg-primary);\n  border-radius: 10px;\n  border: var(--redo-border-width) solid\n    var(--redo-colors-border-border-primary);\n  display: flex;\n  justify-content: space-between;\n  padding: 12px;\n  cursor: pointer;\n  align-items: center;\n  font-size: var(--redo-body-medium-text-size);\n  transition: border var(--redo-state-duration);\n  gap: var(--redo-spacing);\n  line-height: 100%;\n}\n\n._j-2._j-3 {\n    padding: 8px 12px;\n  }\n\n._j-2._j-4 {\n    background-color: var(--redo-colors-background-bg-primary);\n    border: var(--redo-border-width) solid\n      var(--redo-colors-border-border-primary);\n  }\n\n._j-2:hover {\n    border-color: var(--redo-colors-border-border-primary_hover);\n  }\n\n._j-2:disabled {\n    text-decoration: line-through;\n    color: var(--redo-colors-text-text-disabled);\n  }\n\n._j-5 {\n  transition: opacity 250ms;\n  box-shadow: 0px 14px 40px rgba(24, 24, 24, 0.09);\n  margin: var(--redo-spacing);\n}\n\n._j-5._j-6 {\n  background-color: var(--redo-colors-background-bg-primary);\n}\n\n._j-5._j-7,\n._j-5._j-8 {\n  /* opacity: 0; */\n}\n\n._j-9 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px;\n  cursor: pointer;\n}\n\n._j-a {\n  --size: 16px;\n  color: var(--redo-colors-foreground-fg-brand-primary-600);\n  height: var(--size);\n  width: var(--size);\n}\n\n._j-b {\n  color: var(--redo-colors-foreground-fg-quarterary-500);\n  width: var(--redo-icon-medium-size);\n  height: var(--redo-icon-medium-size);\n  transition: color var(--redo-state-duration);\n  flex-shrink: 0;\n}\n\n._j-c {\n  border: 1px solid var(--redo-colors-border-border-secondary);\n}\n", ""]);
// Exports
export var container = "_j-0";
export var label = "_j-1";
export var select = "_j-2";
export var reducedPadding = "_j-3";
export var brand = "_j-4";
export var dropdown = "_j-5";
export var background = "_j-6";
export var enter = "_j-7";
export var exitActive = "_j-8";
export var option = "_j-9";
export var check = "_j-a";
export var selectIcon = "_j-b";
export var line = "_j-c";
export default ___CSS_LOADER_EXPORT___;
